import { render, staticRenderFns } from "./enlargement.vue?vue&type=template&id=4960f6c9&scoped=true&"
import script from "./enlargement.vue?vue&type=script&lang=js&"
export * from "./enlargement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4960f6c9",
  null
  
)

export default component.exports