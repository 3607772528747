<!--  -->
<template>
   
  <div>
    <div
      v-if="ISmagnifyImg"
      id="outerdiv"
      @click="hidemagnifyImg"
      style="
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 9999;
        width: 100%;
        height: 100%;
      "
    >
      <div
        id="innerdiv"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
        v-if="ISmagnifyImg"
      >
        <img
          id="bigimg"
          style="border: 5px solid #fff; width: 100%"
          :src="srcimg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ISmagnifyImg: {
      type: Boolean,
    },
    srcimg: {},
  },
  data() {
    return {};
  },

  components: {},

  methods: {
    hidemagnifyImg() {
      this.$emit("hidemagnifyImg", 1);
    },
  },
};
</script>
<style lang='less' scoped>
</style>
