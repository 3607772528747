<!--  -->
<template>
  <div style="height: 100%">
    <el-card>
      <div class="top">
        筛选专题：
        <el-select
          v-model="value"
          placeholder="请选择专题"
          size="mini"
          filterable
        >
          <el-option
            v-for="item in specialList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          size="mini"
          style="margin-left: 30px"
          @click="OnReset(0)"
          >搜索</el-button
        >
        <el-button type="primary" size="mini" @click="OnReset(1)"
          >重置</el-button
        >
      </div>
      <!-- <div style="margin: 20px auto; width: 80%"> -->
      <el-empty description="暂无数据" v-if="tableData.length == 0"></el-empty>
      <el-table
        v-if="tableData.length > 0"
        :header-cell-style="{
          background: '#F3F7FF',
          color: '#0B1B31',
          fontSize: '14px',
          fontWeight: '500',
        }"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="themeTitle" label="所属专题" align="center">
        </el-table-column>
        <el-table-column prop="fullName" label="提问者" align="center">
        </el-table-column>
        <el-table-column label="提问日期">
          <template slot-scope="scope" align="center">
            {{ currentTime.formatDate(scope.row.createTime * 1000) }}
          </template>
        </el-table-column>
        <el-table-column prop="subjectName" label="课程" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >查看</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- </div> -->
      <!-- //分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="page.pageSize"
        :current-page.sync="page.pageNum"
        layout="total, prev, pager, next"
        :total="page.total"
        background
        style="margin-top: 20px; text-align: center"
      ></el-pagination>
    </el-card>
    <!-- 弹窗 -->
    <el-dialog :title="dialog.title" :visible.sync="dialog.dialogVisible">
      <!-- <el-divider></el-divider> -->
      <div style="height: 630px" v-if="dialog.dialogVisible">
        <el-scrollbar style="height: 20%">
          <div class="dialog">
            <div class="icon">{{ particularsList.fullName.split("")[0] }}</div>
            <div class="con">
              <span class="title" v-html="particularsList.title"></span>
              <span class="name">{{ particularsList.fullName }}</span>
              <p class="time">
                {{ currentTime.formatDate(particularsList.createTime * 1000) }}
              </p>
            </div>
          </div>
        </el-scrollbar>
        <div style="height: 10px"></div>
        <el-scrollbar style="height: 35%">
          <div
            class="dialog"
            v-for="(item, index) in particularsList.answers"
            :key="index"
          >
            <div class="icon">{{ item.fullName.split("")[0] }}</div>
            <div class="con">
              <span class="name">{{ item.fullName }}</span>
              <p class="titles" v-html="item.answer"></p>
              <p class="time">
                {{ currentTime.formatDate(item.createTime * 1000) }}
                <i class="el-icon-delete" @click="deletes(item.id)"
                  ><i style="padding-left: 5px">删除</i></i
                >
              </p>
            </div>
          </div>
        </el-scrollbar>
        <mceEditor v-model="reply" ref="reply" :height="240" />
      </div>

      <div class="bottom">
        <el-button type="primary" @click="OnDialogSet">发送</el-button>
        <el-button @click="OnDialogSet(1)">取消</el-button>
      </div>
    </el-dialog>
    <!-- //ISmagnifyImg 是否显示 srcimg 图片路径 hidemagnifyImg调用是否显示（隐藏） -->
    <enlargement
      @hidemagnifyImg="hidemagnifyImg"
      :ISmagnifyImg="ISmagnifyImg"
      :srcimg="srcimg"
    />
  </div>
</template>

<script>
import mceEditor from "../../materialGather/k12SetQuestion/mceEditor.vue";
import enlargement from "../../../components/enlargement.vue";
import {
  answerListQuestionAnswerTheme,
  questionAnswerSaveAnswer, //回复
  questionAnswerGetQuestionTitlePage, //教师答疑列表
  questionAnswerRemoveQuestionAnswer, //删除答疑答复（答案）
  questionAnswerRemoveQuestionTitle, //删除答疑提出的问题
  questionAnswerGetQuestionTitleById, //教师端通过id来查询答疑详情信息
} from "../../../api/api";
export default {
  data() {
    return {
      ISmagnifyImg: false, //放大的
      srcimg: "",
      value: "",
      activeName: 1,
      dialog: {
        dialogVisible: false,
        title: "提出疑问",
      },
      tableData: [],
      specialList: [{ value: "", label: "全部" }],
      page: {
        pageSize: 10,
        total: 100,
        pageNum: 1,
      },
      reply: "", //回复
      questionId: "", //回复id
      particularsList: [],
    };
  },

  components: {
    mceEditor,
    enlargement,
  },
  watch: {
    "dialog.dialogVisible": {
      handler(newValue, oldValue) {
        if (newValue == false) {
          this.reply = "";
          this.questionId = "";
        }
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    OnReset(val) {
      if (val == 1) {
        //重置
        this.value = "";
      }
      this.page.pageNum = 1;
      this.list();
    },
    init() {
      answerListQuestionAnswerTheme({
        classId: this.$route.query.classId,
      }).then((res) => {
        if (res.message == "请求成功") {
          res.data.map((item) => {
            this.specialList.push({
              label: item.title,
              value: item.id,
            });
          });
        }
      });
      this.list();
    },
    //隐藏弹窗 图片放大
    hidemagnifyImg(val) {
      this.ISmagnifyImg = false;
    },
    //答疑列表
    list() {
      var obj = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        query: {
          classId: this.$route.query.classId,
          themeId: this.value,
        },
      };
      questionAnswerGetQuestionTitlePage(obj).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    //删除个别回复答案
    deletes(id) {
      questionAnswerRemoveQuestionAnswer({ id: id }).then((res) => {
        if (res.message == "请求成功") {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          questionAnswerGetQuestionTitleById({ id: this.questionId }).then(
            (res) => {
              this.particularsList = res.data;
            }
          );
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //提问答疑
    OnDialogSet(type) {
      if (type == 1) {
        this.dialog.dialogVisible = false;
        return;
      }
      if (this.reply == "") {
        this.$message.error("回复内容不可为空");
        return;
      }
      questionAnswerSaveAnswer({
        questionId: this.questionId,
        answer: this.reply,
      }).then((res) => {
        if (res.message == "请求成功") {
          this.$message({
            message: "发送成功",
            type: "success",
          });
          this.reply = "";
          questionAnswerGetQuestionTitleById({ id: this.questionId }).then(
            (res) => {
              this.particularsList = res.data;
            }
          );
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //查看
    handleEdit(index, row) {
      this.questionId = row.id;
      this.dialog.title = "答疑详情";
      questionAnswerGetQuestionTitleById({ id: this.questionId }).then(
        (res) => {
          this.particularsList = res.data;
          this.dialog.dialogVisible = true;
          setTimeout(() => {
            let imgs = document.getElementsByTagName("img");
            for (let i = 0; i < imgs.length; i++) {
              if (i > 0) {
                imgs[i].onclick = () => {
                  this.srcimg = imgs[i].src;
                  this.ISmagnifyImg = true;
                };
              }
            }
          }, 500);
        }
      );
    },
    //删除
    handleDelete(index, row) {
      questionAnswerRemoveQuestionTitle({ id: row.id }).then((res) => {
        if (res.message == "请求成功") {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.list();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.list();
    },
  },
};
</script>
<style lang='less' scoped>
@import "./index.less";
/deep/.el-scrollbar {
  height: 100%;
}
</style>
